import _ from "lodash";
import { Link, useParams } from "react-router-dom";
import Loader from "../../layouts/StoreFront/components/Loader";
import useProducts from "../../hooks/useProducts";
import { useShopCollection } from "../../hooks/useShopCollection";
import { useAppSelector } from "../../app/hooks";
import Caro from "../StoreFront/components/Carousel";
import { Helmet } from "react-helmet-async";
import { addToCart, removeFromCart } from "../../features/cart/cart-slice";
import clsx from "clsx";


// type Props = {
//   product: any;
// };
const Collection = () => {
  const { cartItems } = useAppSelector((state) => state.cart);
  const { shop } = useAppSelector((state) => state?.shop);
  const { id } = useParams();

  const { collection,loading } = useShopCollection({
    filter: {
      id: {
        eq: id,
      },
    },
  });

  // const { collections, loading } = useShopCollections({
  //   filter: {
  //     id: {
  //       eq: id,
  //     },
  //   },
  // });
  const { products } = useProducts({
    filter: {
      shop: {
        shop_id: {
          eq: shop?.id,
        },
      },
      
      shopCollection: {
        collection_id: {
          eq: id,
        },
      },
    },
  });
 
  // const alreadyAdded = !_.isEmpty(
  //   _.find(cartItems, (el) => el.id === product?.id)
  // );

  function dispatch(arg0: { payload: any; type: "cart/removeFromCart"; }) {
    throw new Error("Function not implemented.");
  }

  return (
    <div>
      {loading ?(
        <Loader/>
      ):(
    <div className="max-w-6xl min-h-screen mx-auto">
      <Helmet>
      <title>{collection?.name + " -  Buy " + collection?.name + " with free delivery on selected products" }</title>
        <meta name='description' content={"Welcome to my shop | "+shop?.name ?? "" }/>
      </Helmet>
      <Caro />
      <div className="mx-4 my-8 text-black text-2xl font-medium">Shop items from this collection</div>
      <div className="grid md:grid-cols-4 mt-6">
        {products?.map((product, index) => (
          
          <Link
            to={{
              pathname: `/product/${product?.id}`,
            }}
            key={index}
            className="group relative flex flex-col overflow-hidden rounded-lg border mx-4 mb-8 border-gray-200 bg-white"
          >
           <div className="aspect-w-36 aspect-h-4  group-hover:opacity-75 sm:aspect-none lg:h-64 sm:h-64">
      <Link
          to={{
            pathname: `/product/${product?.id}`,
          }}
        >
        <img
          src={product?.avatar}
          alt={product?.title}
          className="lg:h-64 sm:h-64 w-full border-2   object-center  sm:w-full"
        />
        </Link>
      </div>
      <div className="flex flex-1 space-y-2 p-4 ">
        
        <div className=" flex flex-col space-y-2">
          <div>
          <Link
        to={{
          pathname: `/product/${product?.id}`,
        }}
      >
          <h3 aria-hidden="true" className="text-sm font-medium   text-gray-900 ">
          {/* <button > */}
            {/* <span aria-hidden="true" className="absolute inset-0"></span> */} 
            {product?.title}
           {/* </button> */}
        </h3>
        </Link>
      
          </div>
          
        
        <div>
        <p className=" font-bold pt-0.5 text-gray-900  text-xs">
            {product?.currency}{" "}
            {_.sum([product?.price, product?.profit_added])}
          </p>
        </div>
        {/* <div>
          ratings
        </div> */}
        
          {/* <div>
          <button
            onClick={() => {
              alreadyAdded
                ? dispatch(removeFromCart(product))
                : dispatch(addToCart(product));
            }}
            className={clsx({
              "text-black w-full px-2 py-1 bg-white border-2 border-gray-500 rounded-md text-xs": !alreadyAdded,
              "text-white px-2 py-1 bg-pink-500 rounded-md w-full border-gray-500 text-xs": alreadyAdded,
            })}
          >
            {!alreadyAdded ? "Add to Cart" : "Remove"}
          </button>
          </div> */}

        </div>
         
     
      </div>
          </Link>
        ))}
      </div>
    </div>
      )}
    </div>
  );
};

export default Collection;
