import ProductCard from "../../components/ProductCard/ProductCard";
import useProducts from "../../hooks/useProducts";
import { useAppSelector } from "../../app/hooks";

type Props = {};

const RecentlyViewed = (props: Props) => {
  const { recentlyViewedProducts } = useAppSelector((state) => state.product);
  const { shop } = useAppSelector((state) => state.shop);

  const convertedItemsToString = () => {
    const itemIds: string[] = [];

    recentlyViewedProducts?.map((item) => {
      itemIds.push(String(item));
    });

    return itemIds;
  };

  console.log(shop);
  console.log(recentlyViewedProducts);

  const { products } = useProducts({
    filter: {
      shop: {
        shop_id: {
          eq: shop?.id,
        },
      },
      id: {
        in: convertedItemsToString(),
      },
    },
  });

  return (
    <div className="ml-24">
      <div>
        <h1>Recently Viewed</h1>
      </div>

      <section className="mt-4 w-full ">
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-y-2 gap-x-4">
          {products.map((product: any, index: number) => {
            return <ProductCard product={product} key={index} />;
          })}
        </div>
      </section>
    </div>
  );
};

export default RecentlyViewed;
