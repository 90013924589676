import { GET_PRODUCTS } from "./../graphql/queries/queries";
import { useQuery } from "@apollo/client";
import { GetProductsQuery, GetProductsQueryVariables } from "../graphql/queries/__generated__/queries";

const useProducts = (variables?: GetProductsQueryVariables) => {
  const { data, loading } = useQuery<GetProductsQuery, GetProductsQueryVariables>(GET_PRODUCTS, {
    fetchPolicy: "network-only",
    variables,
    notifyOnNetworkStatusChange: true
  });
  return {
    products: data?.getProducts ?? [],
    loading,
  };
};

export default useProducts;
