import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { useState } from "react";
import { error } from "console";
import { useMutation } from "@apollo/client";
import { REQUEST_ORDER_OTP, VERIFY_OTP } from "../../graphql/mutations/mutations";
import { notification } from "antd";
import {
  GetOrderOtpMutation,
  GetOrderOtpMutationVariables,
  VerifyOtpMutation,
  VerifyOtpMutationVariables,
} from "../../graphql/mutations/__generated__/mutations";
import { VerifyOrderOtpMutation,VerifyOrderOtpMutationVariables } from "../../graphql/mutations/__generated__/mutations";
import { REQUEST_OTP,VERIFY_ORDER_OTP } from "../../graphql/mutations/mutations";
import { RequestOtpMutation,RequestOtpMutationVariables } from "../../graphql/mutations/__generated__/mutations";
import { ChannelType } from "../../graphql/types";
import useDigitalOrder from "../../hooks/useDigitalOrder";
const InputOTPPage = () => {
  const { id } = useParams();
  const { userInfo } = useAppSelector((state) => state.auth);
 // console.log(userInfo)
  const navigate = useNavigate();
  const { invoice } = useParams();
  const { digitalorder,loading:digitalOrderLoading } = useDigitalOrder({
    filter: {
      id: {
        eq: invoice,
      },
    },
  });
  const [otpValues, setOtpValues] = useState(["", "", "", ""]);
  const [verify, { loading }] = useMutation<
    VerifyOrderOtpMutation,
    VerifyOrderOtpMutationVariables
  >(VERIFY_ORDER_OTP);
  const handleOtpChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newOtpValues = [...otpValues];
    newOtpValues[index] = e.target.value;
    setOtpValues(newOtpValues);
  };
  const [getOTP, { loading: gettingOTP }] = useMutation<
GetOrderOtpMutation,
GetOrderOtpMutationVariables
>(REQUEST_ORDER_OTP);
  const openNotification = (type: "success" | "error" | "info" | "warning", message: string, description: string) => {
    notification[type]({
      message: message,
      description: description,
      duration: 4,  // Set how long the notification should last
      placement: 'topRight',  // Set where the notification will appear
    });
  }
  const request = ()=>{
    getOTP({
      variables:{
        "customerId":userInfo!?.id || digitalorder!.customerId,
        "orderId":invoice!
      }})
      .then((res)=>{
        if (res.data?.getOrderOtp) {
          //navigate(`/input-otp/${res.data?.requestOTP?.token}/${id}`);
          openNotification("success", "Success", "OTP has been resent!")
        }
        
        
      })
      .catch((err)=>{
        console.log(err);
      })
  }

  const handleOtpSubmit = async () => {
    const enteredOtp = otpValues.join("");
   // console.log(enteredOtp);
    try {
      const result = await verify({
        variables: {
          "orderId" :invoice!,
          "customerId":userInfo!?.id || digitalorder!.customerId,
          "otp":enteredOtp
        },
      });
      if (result.data?.verifyOrderOtp) {
        navigate(`/preview-digital-product/${id}`);
      } else {
       // console.log("OTP verification failed:", result.data?.verifyOrderOtp);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  return (
    <div className="min-h-full pb-12 flex flex-col my-11">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-16 flex justify-center items-center">
          <div className="text-center bg-white w-full mx-4 sm:mx-0 sm:w-1/2 py-10 rounded-2xl">
            <h1 className="mt-2 text-2xl font-semibold text-gray-900 tracking-tight">
              Enter OTP to continue
            </h1>
            <p className="mt-2 text-base text-gray-500">
              {`An otp has been sent to your email ${userInfo?.email || digitalorder?.customer?.email}`}
            </p>

            {/* OTP inputs */}
            <div className="mt-6 flex justify-center space-x-3">
              {otpValues.map((otp, index) => (
                <input
                  key={index}
                  type="text"
                  value={otp}
                  maxLength={1}
                  onChange={(e) => handleOtpChange(e, index)}
                  className="w-12 h-12 border border-gray-300 rounded-lg text-center text-xl"
                />
              ))}
            </div>

            {/* Resend and Confirm buttons */}
            <div className="mt-6 flex flex-col items-center">
            <button
                className="w-1/2 py-3 mb-3 bg-pink-600 text-white rounded-full text-lg font-semibold"
                onClick={handleOtpSubmit}
              >
                Confirm
              </button>
              <button onClick={request} className="text-gray-500 text-sm  border border-gray-500 hover:text-white w-1/2 rounded-full py-3 hover:bg-black">Resend</button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default InputOTPPage;
